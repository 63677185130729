import './Login.css';
import React from 'react';
import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import BackendServices from '../BackendServices';
import {connect} from 'react-redux';
import * as auth from '../redux/Auth'
import NqImage from './NqImage';
import {Link} from 'react-router-dom';
import ErrorModal from './ErrorModal';
import {Row, Col, Container, Form, Button} from 'react-bootstrap';
import i18n from '../i18n';

export default connect(
  undefined,
  dispatch => ({
    setLogin: (userRoles) => dispatch(auth.actions.setLogin(userRoles))
  })
)(class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
      accountDisabledMsg: false,
      errorMsgHtml: i18n.t('account.locked'),
    };
  }

  loginFailed() {
    this.setState({loginFailed: true});
  }

  showAccountDisabledMsg = (errorType, adminEmails) => {
    let errorMsgHtml = i18n.t('account.not_activated');
    if (errorType === 'lock') {
      errorMsgHtml = i18n.t('account.locked');
    }
    if (adminEmails && adminEmails.length > 0) {
      errorMsgHtml = errorMsgHtml.replaceAll('service@ncore-build.de', adminEmails.join(', '))
    }
    this.setState({errorMsgHtml: errorMsgHtml, accountDisabledMsg: true});
  };

  hideAccountDisabledMsg = () => {
    this.setState({accountDisabledMsg: false});
  };

  async login(values, setSubmitting) {
    this.setState({loginFailed: false});

    try {
      const result = await BackendServices.loginWithRoles(values.username, values.password);
      if (!BackendServices.wasSuccessful(result)) {
        if (this.errorCodeIsBadRequest(result) && this.errorReasonIsAccountDeactivated(result)) {
          this.showAccountDisabledMsg(result.backendResponse.extractedBody.nq_error_type, result.backendResponse.extractedBody.nq_admin_emails);
        } else {
          this.loginFailed();
        }
      } else {
        this.props.setLogin(result.userRoles);
        let {from} = this.props.location.state || {from: {pathname: '/'}};
        this.props.history.replace(from);
      }
    } catch (e) {
      this.loginFailed();
    }
    setSubmitting(false);
  }

  errorReasonIsAccountDeactivated(result) {
    return result.backendResponse.extractedBody.error_description === 'Account disabled';
  }

  errorCodeIsBadRequest(result) {
    return result.backendResponse.status === 400;
  }

  render() {

    return (
      <React.Fragment>
        <ErrorModal show={this.state.accountDisabledMsg}
          errorMsgHtml={this.state.errorMsgHtml}
          onClose={this.hideAccountDisabledMsg}/>
        <div className="login">
          <Container>
            <Row>
              <Col sm={9} md={7} lg={5} className="mx-auto">
                <div className="card-signin">
                  <NqImage className="img-fluid" src="/ncore_logo_full_color.png" alt="NQ"/>
                  <h2>{i18n.t('sign-in.text')}</h2>

                  <Formik
                    initialValues={{username: '', password: ''}}
                    onSubmit={(values, {setSubmitting}) => {
                      this.login(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                      username: Yup.string()
                        .email(i18n.t('email.require.valid'))
                        .required(i18n.t('email.require.email')),
                      password: Yup.string()
                        .required(i18n.t('password.input.password'))
                    })}>
                    {props => {
                      const {
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      } = props;
                      return (

                        <Form onSubmit={handleSubmit} className="form-signin">
                          <Form.Group>
                            <label htmlFor="username">{i18n.t('username')}</label>
                            <input id="username" type="text"
                              className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                              onChange={handleChange} onBlur={handleBlur}/>
                            <ErrorMessage component="div" name="username"
                              className="invalid-feedback"/>
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="password">{i18n.t('password.text')}</label>
                            <input type="password" id="password"
                              className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                              onChange={handleChange} onBlur={handleBlur}/>
                            <ErrorMessage component="div" name="password"
                              className="invalid-feedback"/>
                          </Form.Group>
                          <Button id="sign-in-button" variant="primary" type="submit"
                            disabled={isSubmitting} className="mb-4" block={true}>
                            {i18n.t('sign-in.text')}
                          </Button>
                          {this.state.loginFailed &&
                            <div id="sign-in-error" className="alert alert-danger"
                              role="alert">
                              {i18n.t('sign-in.fail')}
                            </div>}
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={3}>
                <Link id="recover-password" to="/passwort-vergessen">{i18n.t('password.recover')}</Link>
                <span> / </span>
                <a id="legal-notice" href="https://ncore-build.de/footer/impressum" target="_blank"
                  rel="noopener noreferrer ">{i18n.t('legal-details')}</a>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
})
